import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import {
    navigation,
    NavigationItemAppManagement,
    NavigationItemHealthDoctors,
    NavigationItemHealthPatients,
    NavigationItemMarketingManagement,
    NavigationItemProfile,
    NavigationItemScheduleCalendar,
    NavigationItemSystemEmployees,
    NavigationItemSystemIntegrations,
    NavigationItemTenants,
    NavigationItemSettings,
    NavigationConfigHealthProcedures,
    NavigationConfigProfessionalProcedures,
    NavigationConfigHealthInsurance,
    NavigationConfigHealthCareUnits,
    NavigationItemClinicSettings,
    NavigationConfigGeneralInfo,
    NavigationConfigSchedule,
    NavigationConfigMedicalRecord,
    NavigationItemPay,
    NavigationConfigDocumentTemplates,
    NavigationReport,
    NavigationDashboardItem,
    NavigationConfigPatient,
    NavigationConfigChildGrowthCurve,
    NavigationFinancial,
    NavigationFinancialConfig,
    NavigationFinancialCashFlow,
    NavigationFinancialRevenues,
    NavigationFinancialExpenses,
    NavigationItemUrlGenerator,
    NavigationItemGlobalStats,
    NavigationReportAppointments,
    NavigationReportAttendances,
    NavigationMarketingPushNotification,
    NavigationMarketingPushNotificationHistory,
    NavigationAppManagementSettings,
    NavigationAppManagementModules,
    NavigationConfigHeathEngage,
} from "app/navigation/navigation";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationPortugueseBrasil } from "app/navigation/i18n/pt-BR";
import { PermissionName } from "./wapps/core/permissions-name";
import { HealthPermissionNames } from "./wapps/health/permissions-names";
import { SignalRAspNetCoreHelper } from "@shared/helpers/SignalRAspNetCoreHelper";
import { NavigationEnd, Router } from "@angular/router";
import { AppSessionService } from "@shared/session/app-session.service";
import { AppAuthService } from "@shared/auth/app-auth.service";
import { AppConsts } from "@shared/AppConsts";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { MatInput } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import { MatDialog } from "@angular/material/dialog";
import { PendingTermsOfServiceSignatureComponent } from "@app/wapps/legal/pending-terms-of-service/pending-terms-of-service-signature.component";
import { PendingTermsOfServiceSignatureService } from "@app/wapps/legal/pending-terms-of-service/pending-terms-of-service-signature.service";
import { FinancialConfigService } from "@app/wapps/financial/financial-config.service";
import { CoreFeatures } from "./wapps/core/feature-names";
import { HealthFeatures } from "@app/wapps/health/feature-names";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { SubscriptionPermissionName } from "@app/wapps/system/subscription/permissions-name";
import { HtmlInjectorService } from "@app/HtmlInjectorService";
import { MomentLocaleService } from "@app/moment-locale.service";
import { ChangelogService } from "./wapps/changelog/changelog.service";
import { TourService } from "ngx-ui-tour-md-menu";
import { VTourService } from "./wapps/core/tour/vtour-serivce.service";
import { PrescriptionConfigService } from "app/wapps/prescription/prescription-config.service";

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _router: Router,
        private appSession: AppSessionService,
        private _appAuth: AppAuthService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private _gtmService: GoogleTagManagerService,
        public dialog: MatDialog,
        private _pendingTermsOfServiceSignatureService: PendingTermsOfServiceSignatureService,
        private _financialConfigService: FinancialConfigService,
        private _htmlInjectorService: HtmlInjectorService,
        private _momentLocaleService: MomentLocaleService,
        private _changelogService: ChangelogService,
        public tourService: TourService,
        private vTourService: VTourService,
        private _prescriptionConfigService: PrescriptionConfigService
    ) {
        this._momentLocaleService.setLocaleFromBrowser();
        // console.log('HMR is not enabled for webpack-dev-server!');
        // Get default navigation

        iconRegistry.addSvgIcon("bell", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/bell.svg"));

        iconRegistry.addSvgIcon(
            "calendar",
            sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/calendar.svg")
        );

        iconRegistry.addSvgIcon("chat", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/chat.svg"));

        iconRegistry.addSvgIcon(
            "checklist",
            sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/checklist.svg")
        );

        iconRegistry.addSvgIcon("contact", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/contact.svg"));

        iconRegistry.addSvgIcon("delete", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/delete.svg"));

        iconRegistry.addSvgIcon("doctor", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/doctor.svg"));

        iconRegistry.addSvgIcon("edit", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/edit.svg"));

        iconRegistry.addSvgIcon("eye", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/eye.svg"));

        iconRegistry.addSvgIcon("home", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/home-run.svg"));

        iconRegistry.addSvgIcon("lanyard", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/lanyard.svg"));

        iconRegistry.addSvgIcon("layout", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/layout.svg"));

        iconRegistry.addSvgIcon("search", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/search.svg"));

        iconRegistry.addSvgIcon(
            "settings",
            sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/settings.svg")
        );

        iconRegistry.addSvgIcon("user", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/user.svg"));

        iconRegistry.addSvgIcon("mkt", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/mkt.svg"));

        iconRegistry.addSvgIcon(
            "integrations",
            sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/integrations.svg")
        );

        iconRegistry.addSvgIcon(
            "patients",
            sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/patients.svg")
        );

        iconRegistry.addSvgIcon("report", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/report.svg"));

        iconRegistry.addSvgIcon("mobile", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/mobile.svg"));

        iconRegistry.addSvgIcon("vpay", sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/vpay.svg"));

        iconRegistry.addSvgIcon(
            "dashboard",
            sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/dashboard.svg")
        );

        iconRegistry.addSvgIcon(
            "financial",
            sanitizer.bypassSecurityTrustResourceUrl("assets/images/icons/financial.svg")
        );

        iconRegistry.addSvgIcon(
            "glasses",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/glasses-solid.svg")
        );

        iconRegistry.addSvgIcon(
            "mobile-sparkles",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/mobile-app.svg")
        );

        iconRegistry.addSvgIcon(
            "view-appointment",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/view-appointment.svg")
        );

        iconRegistry.addSvgIcon(
            "add-appointment",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/add-appointment.svg")
        );

        iconRegistry.addSvgIcon(
            "patient-group",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/patient-group.svg")
        );

        iconRegistry.addSvgIcon(
            "doctor",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/doctor.svg")
        );

        iconRegistry.addSvgIcon(
            "procedure",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/procedure.svg")
        );

        iconRegistry.addSvgIcon(
            "health-insurance",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/health-insurance.svg")
        );

        iconRegistry.addSvgIcon(
            "care-unit",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/care-unit.svg")
        );

        iconRegistry.addSvgIcon(
            "file",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/file.svg")
        );

        iconRegistry.addSvgIcon(
            "notification",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/notification.svg")
        );

        iconRegistry.addSvgIcon(
            "pencil",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/pencil.svg")
        );

        iconRegistry.addSvgIcon(
            "content",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/content.svg")
        );

        iconRegistry.addSvgIcon(
            "whatsapp",
            sanitizer.bypassSecurityTrustResourceUrl("../../../assets/images/icons/whatsapp.svg")
        );

        this._appAuth.onLogin.subscribe((value) => {
            let nav = navigation[0];
            nav.children.splice(0, nav.children.length);

            if (value === true && !!this.appSession.userId) {
                this._pendingTermsOfServiceSignatureService
                    .checkForPendingTermsOfServiceSignature()
                    .subscribe((info) => {
                        if (info.contentLink) {
                            const dialogRef = this.dialog.open(PendingTermsOfServiceSignatureComponent, {
                                width: "90%",
                                data: info,
                                disableClose: info.required,
                                closeOnNavigation: !info.required,
                            });
                            dialogRef.afterClosed().subscribe();
                        }
                    });

                if (!this.appSession.tenantId) {
                    nav.children.push(NavigationItemGlobalStats);

                    nav.children.push(NavigationItemTenants);

                    if (abp.auth.isGranted(SubscriptionPermissionName.PaymentLink)) {
                        nav.children.push(NavigationItemUrlGenerator);
                    }

                    this._router.navigate(["stats"]).then();
                    return;
                }

                if (
                    abp.features.isEnabled(HealthFeatures.Dashboard) &&
                    (abp.auth.isGranted(PermissionName.Appointments) ||
                        abp.auth.isGranted(PermissionName.AppointmentsQueryAll) ||
                        abp.auth.isGranted(PermissionName.AppointmentsQueryMine) ||
                        abp.auth.isGranted(PermissionName.AppointmentsCreate))
                ) {
                    nav.children.push(NavigationDashboardItem);
                }

                if (
                    abp.features.isEnabled(HealthFeatures.Schedule) &&
                    (abp.auth.isGranted(PermissionName.Appointments) ||
                        abp.auth.isGranted(PermissionName.AppointmentsQueryAll) ||
                        abp.auth.isGranted(PermissionName.AppointmentsQueryMine) ||
                        abp.auth.isGranted(PermissionName.AppointmentsCreate))
                ) {
                    nav.children.push(NavigationItemScheduleCalendar);
                }

                if (
                    abp.auth.isGranted(PermissionName.Patients) ||
                    abp.auth.isGranted(PermissionName.PatientsQuery) ||
                    abp.auth.isGranted(PermissionName.PatientsCreate) ||
                    abp.auth.isGranted(PermissionName.PatientsUpdate) ||
                    abp.auth.isGranted(PermissionName.PatientsDelete)
                ) {
                    nav.children.push(NavigationItemHealthPatients);
                }

                if (
                    abp.features.isEnabled(CoreFeatures.VPay) &&
                    (this.appSession.isAdmin() || this.appSession.isDoctor())
                ) {
                    nav.children.push(NavigationItemPay);
                }

                if (this._financialConfigService.isMenuEnabled()) {
                    let menu = NavigationFinancial;
                    if (this._financialConfigService.allowAccessConfig()) {
                        menu.children.push(NavigationFinancialConfig);
                    }

                    if (this._financialConfigService.allowAccessRevenues()) {
                        menu.children.push(NavigationFinancialRevenues);
                    }

                    if (this._financialConfigService.allowAccessExpenses()) {
                        menu.children.push(NavigationFinancialExpenses);
                    }

                    if (this._financialConfigService.allowAccessReports()) {
                        menu.children.push(NavigationFinancialCashFlow);
                    }

                    nav.children.push(menu);
                }

                if (abp.features.isEnabled(CoreFeatures.Report) && abp.auth.isGranted(PermissionName.Report)) {
                    let menu = NavigationReport;
                    if (
                        abp.features.isEnabled(HealthFeatures.ReportSchedules) &&
                        abp.auth.isGranted(PermissionName.ReportAppointmentQuery)
                    ) {
                        menu.children.push(NavigationReportAppointments);
                    }

                    if (
                        abp.features.isEnabled(HealthFeatures.ReportAttendance) &&
                        abp.auth.isGranted(PermissionName.ReportAppointmentQuery)
                    ) {
                        menu.children.push(NavigationReportAttendances);
                    }

                    nav.children.push(menu);
                }

                if (
                    (abp.features.isEnabled(AppConsts.Features.FeaturePushNotification) ||
                        abp.features.isEnabled(AppConsts.Features.FeaturePushNotificationLegacy)) &&
                    abp.auth.isGranted(PermissionName.Marketing)
                ) {
                    let menu = NavigationItemMarketingManagement;
                    if (abp.auth.isGranted(PermissionName.MarketingPushNotificationCreate)) {
                        menu.children.push(NavigationMarketingPushNotification);
                    }

                    if (abp.auth.isGranted(PermissionName.MarketingPushNotificationHistory)) {
                        menu.children.push(NavigationMarketingPushNotificationHistory);
                    }

                    nav.children.push(menu);
                }

                if (
                    (abp.features.isEnabled(AppConsts.Features.MobileAppBuilder) ||
                        abp.features.isEnabled(AppConsts.Features.Pwa)) &&
                    abp.auth.isGranted(PermissionName.Wizard)
                ) {
                    let menu = NavigationItemAppManagement;
                    if (abp.auth.isGranted(PermissionName.WizardDesignQuery)) {
                        menu.children.push(NavigationAppManagementSettings);
                    }

                    if (abp.auth.isGranted(PermissionName.WizardModulesQuery)) {
                        menu.children.push(NavigationAppManagementModules);
                    }

                    nav.children.push(menu);
                }

                if (
                    abp.auth.isGranted(HealthPermissionNames.ProceduresPermissionQueryMine) ||
                    this.appSession.isUserInRoleTenant(AppConsts.RolesNames.Doctor)
                ) {
                    nav.children.push(NavigationConfigProfessionalProcedures);
                }

                let settingsItem = NavigationItemSettings;
                settingsItem.children.push(NavigationItemProfile);

                if (this.appSession.isAdmin()) {
                    settingsItem.children.push(NavigationConfigGeneralInfo);
                }

                let globalSettingsItem = NavigationItemClinicSettings;
                if (
                    this.appSession.isAdmin() &&
                    abp.auth.isAnyGranted(
                        HealthPermissionNames.ProceduresPermission,
                        HealthPermissionNames.InsurancePermission,
                        HealthPermissionNames.CareUnitsPermission,
                        PermissionName.Users
                    )
                ) {
                    if (abp.auth.isAnyGranted(HealthPermissionNames.InsurancePermissionQuery)) {
                        globalSettingsItem.children.push(NavigationConfigHealthInsurance);
                    }

                    if (
                        abp.auth.isAnyGranted(
                            HealthPermissionNames.CareUnitsPermission,
                            HealthPermissionNames.CareUnitsPermissionQuery
                        )
                    ) {
                        globalSettingsItem.children.push(NavigationConfigHealthCareUnits);
                    }

                    if (abp.auth.isAnyGranted(PermissionName.Doctors, PermissionName.DoctorsQuery)) {
                        globalSettingsItem.children.push(NavigationItemHealthDoctors);
                    }

                    if (abp.auth.isAnyGranted(PermissionName.Users, PermissionName.UsersQuery)) {
                        globalSettingsItem.children.push(NavigationItemSystemEmployees);
                    }

                    if (
                        abp.auth.isAnyGranted(
                            HealthPermissionNames.ProceduresPermission,
                            HealthPermissionNames.ProceduresPermissionQuery
                        )
                    ) {
                        globalSettingsItem.children.push(NavigationConfigHealthProcedures);
                    }

                    settingsItem.children.push(globalSettingsItem);
                }

                if (
                    (abp.features.isEnabled(AppConsts.Features.ScheduleGoogleIntegration) ||
                        abp.features.isEnabled(AppConsts.Features.Webhooks)) &&
                    abp.auth.isAnyGranted(
                        PermissionName.Integrations,
                        PermissionName.IntegrationsQuery,
                        PermissionName.GoogleCalendarIntegration
                    )
                ) {
                    settingsItem.children.push(NavigationItemSystemIntegrations);
                }

                if (this.appSession.isAdmin()) {
                    settingsItem.children.push(NavigationConfigPatient);
                }

                if (abp.features.isEnabled(HealthFeatures.Schedule) && this.appSession.isAdmin()) {
                    settingsItem.children.push(NavigationConfigSchedule);
                }

                if (
                    (abp.features.isEnabled(HealthFeatures.MedicalRecord) ||
                        HealthFeatures.isSmartPrescriptionEnabled()) &&
                    this.appSession.isAdmin()
                ) {
                    settingsItem.children.push(NavigationConfigMedicalRecord);
                }

                if (abp.features.isEnabled(HealthFeatures.Engage) && this.appSession.isAdmin()) {
                    settingsItem.children.push(NavigationConfigHeathEngage);
                }

                if (abp.features.isEnabled(HealthFeatures.MedicalRecord) && this.appSession.isAdmin()) {
                    settingsItem.children.push(NavigationConfigChildGrowthCurve);
                }

                if (
                    (abp.features.isEnabled(HealthFeatures.Files) && this.appSession.isAdmin()) ||
                    this.appSession.isDoctor()
                ) {
                    settingsItem.children.push(NavigationConfigDocumentTemplates);
                }

                nav.children.push(settingsItem);

                this._changelogService.getLastDisplayedVersion();
            }
        });

        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");

        // Add languages
        this._translateService.addLangs(["pt-BR"]);

        // Set the default language
        this._translateService.setDefaultLang("pt-BR");

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationPortugueseBrasil);

        // Use a language
        this._translateService.use("pt-BR");

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        const requiredImplementation = {
            get: function (): boolean {
                if (this._required) {
                    return this._required;
                }

                // The required attribute is set
                // when the control return an error from validation with an empty value
                if (this.ngControl && this.ngControl.control && this.ngControl.control.validator) {
                    const emptyValueControl = Object.assign({}, this.ngControl.control);
                    (emptyValueControl as any).value = null;
                    return "required" in (this.ngControl.control.validator(emptyValueControl) || {});
                }
                return false;
            },
            set: function (value: boolean) {
                this._required = coerceBooleanProperty(value);
            },
        };
        Object.defineProperty(MatInput.prototype, "required", requiredImplementation);
        Object.defineProperty(MatSelect.prototype, "required", requiredImplementation);

        this._htmlInjectorService.init();

        this._prescriptionConfigService.prescriptionCommentsOrder$.subscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        SignalRAspNetCoreHelper.initSignalR();
        this._router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((item) => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: "page",
                    pageName: item.url,
                };
                this._gtmService.pushTag(gtmTag).then();
            }
        });

        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;

            if (this.fuseConfig.layout.width === "boxed") {
                this.document.body.classList.add("boxed");
            } else {
                this.document.body.classList.remove("boxed");
            }
        });
        window.addEventListener("message", (event) => {
            if (event.data.message === "User not authenticated in v2") {
                this._router.navigate(["account/login"]);
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    showLater() {
        this.vTourService.endWithProps({ markTourAsVisualized: false });
    }
}
