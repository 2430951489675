import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Alert } from "./alert.model";

@Injectable({
    providedIn: "root",
})
export class AlertService {
    alertInfoEmitter$: BehaviorSubject<Alert[]> = new BehaviorSubject<Alert[]>(null);

    alerts = [];

    constructor() {}

    publishAlert(newAlert: Alert) {
        if (newAlert?.id) {
            const findAlertById = this.alerts.find((element) => element.id === newAlert.id);
            if (findAlertById) {
                return this.alertInfoEmitter$.next(this.alerts);
            }
            this.alerts.push(newAlert);
            return this.alertInfoEmitter$.next(this.alerts);
        }
    }

    dismiss(alert: Alert) {
        const alertToDismiss = this.alerts.find((element) => alert.id === element.id);
        alertToDismiss.isHidden = true;
    }

    execute(alert: Alert) {
        alert.action(alert);
        this.dismiss(alert);
    }
}
