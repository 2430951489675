import { Component, OnInit, Injector } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AlertService } from "./alert.service";
import { AppComponentBase } from "@shared/app-component-base";
import { Alert } from "./alert.model";

@Component({
    selector: "app-alert-component",
    templateUrl: "./alert.component.html",
    styleUrls: ["./alert.component.scss"],
})
export class AlertComponent extends AppComponentBase implements OnInit {
    protected _unsubscribeAll: Subject<any>;

    alerts: Alert[] = [];

    constructor(injector: Injector, private _alertService: AlertService) {
        super(injector);
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._alertService.alertInfoEmitter$.pipe(takeUntil(this._unsubscribeAll)).subscribe((info) => {
            this.alerts = info;
            this.changeDetector.detectChanges();
        });
    }
}
